import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { metadata } from "../utils/metadata";

function SEO({
               title = metadata.home.title.label,
               description = metadata.home.description.label,
               noIndex = false,
               children,
             }) {
  const noIndexPaths = ["/widgets/"];
  const { pathname } = useLocation();

  return (
    <Helmet htmlAttributes={{ lang: "en" }}>
      <title>{title}</title>
      <meta id="description" name="description" content={description} />
      {(noIndex || noIndexPaths.some((item) => pathname.includes(item))) && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      {children}
      <meta name="google-site-verification" content="-g6roC97u6KzFefEgE3Tp9qA9cKvwdOt6EwFDwc615s" />
    </Helmet>
  );
}

export default SEO;
